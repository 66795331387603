(function () {
    'use strict';

    angular.module('informaApp')
        .component('diseaseHierarchy', {
            templateUrl: 'components/disease-view/disease-hierarchy/template.html',
            controller: ['$element', DiseaseHierarchyController],
            bindings: {
                diseases: '<',
                activeDiseaseId: '<',
                onActiveItemChange: '<',
                offsetTop: '<'
            }
        });

    function DiseaseHierarchyController($element) {
        var vm = this;

        vm.activeItemId = null;

        this.$onInit = function () {
            chunkIndications();
            changeActiveElementOnScroll();
        };

        this.$onChanges = function (changes) {
            if (changes.activeDiseaseId && changes.activeDiseaseId.currentValue !== null) {
                scrollToDisease(changes.activeDiseaseId.currentValue.id);
            }
        };



        function chunkIndications() {
            var columnsCount = 3;

            _.forEach(vm.diseases, function (disease) {
                _.forEach(disease.subDiseases, function (subDisease) {
                    var sortedData = _.sortBy(subDisease.indications, 'name');

                    subDisease.indications = _.chunk(sortedData, columnsCount);
                });
            });
        }

        function changeActiveElementOnScroll() {
            $(window).on("scroll resize", function () {
                var active = getActiveElement();

                var id = active ? parseInt(active.dataset.id) : null;

                if (vm.activeItemId !== id) {
                    vm.activeItemId = id;

                    if (vm.onActiveItemChange) {
                        vm.onActiveItemChange(vm.activeItemId);
                    }
                }
            });
        }

        function getActiveElement() {
            var scrollPositionY = $(window).scrollTop() + (vm.offsetTop ? vm.offsetTop : 0);
            var rows = $('.disease-row').toArray();

            return _.find(rows, function (x) {
                var element = $(x);

                return element.next().offset() && scrollPositionY >= element.offset().top && scrollPositionY <= element.next().offset().top;
            });
        }

        function scrollToDisease(id) {
            var offset = (vm.offsetTop ? vm.offsetTop / 2 : 0);
            var element =  $element.find('.disease-row[data-id=' + id + ']');

            $('html, body').animate({
                scrollTop: element.offset().top - offset
            }, 1000);
        }
    }
})();